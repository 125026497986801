@import '../imports';

.custom-range-input {
    overflow: hidden;
    &__header {
        padding: 0px 20px 0px 18px;
        display: flex;
        justify-content: space-between;
    }
    &__label {
        @extend %text--size-h3;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        span {
            @extend %text--size-h5;
            margin-bottom: 8px;
            color: $color-la-2;
            text-transform: lowercase;
        }
        &--min {
            align-items: start;
        }
        &--max {
            text-align: right;
        }
    }
    .input-range {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        &__label {
            display: none;
        }
        &__track {
            left: 20px;
            height: 1px;
            width: 88%;
            background: $color-la-2;
        }
        &__track--active {
            background: $color-accent;
        }
        &__slider {
            transform: translateY(-25%);
            height: 2em;
            width: 2em;
            background: $color-accent;
            border-color: $color-accent;
        }
       
    }
}