@import '../imports';
@import '../blocks/nav-panel';
@import '../components/info-card';
@import '../components/object-card';
@import '../components/report-card';
@import '../blocks/user-objects';
@import '../blocks/user-reports';

.home {
    background-color: $dark-bg;
    &__greeting {
        margin-bottom: 32px;
        h2 {
            @extend %text--size-h1;
            margin-bottom: 10px;
        }
        p {
            @extend %text--size-p3;
            color: $color-la-2;
        }
    }
    &__info-cards {
        margin-bottom: 16px;
    }
    &__objects {
        padding-bottom: 24px;
        border-bottom: 1px solid $color-la-4;
        background-color: $default-bg;
    }
    &__reports {
        padding-bottom: 16px;
        background-color: $default-bg;
    }
}