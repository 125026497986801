@import '../imports';

.objects {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: $dark-bg;
    &__user-objects {
        padding-bottom: 32px;
        background-color: $default-bg;
        .user-objects {
            padding-bottom: 16px;
        }
    }
    &__list {
        padding-bottom: 48px;
        max-height: 73.5vh;
        overflow: scroll;
    }
    &__add-object {
        position: fixed;
        bottom: 16px;
        width: calc(100% - 32px) !important;
    }
}