.checkbox {
    position: relative;
    cursor: pointer;
    &__label {
        display: flex;
        align-items: center;
        &:before {
            content: '';
            margin-right: 16px;
            height: 20px;
            min-width: 20px;
            border: 1px solid rgba(120, 203, 187, 0.32);
            display: block;
            align-self: flex-start;
            box-sizing: border-box;
            background-color: transparent;
            transition: $trans;
            
        }
        &:after {
            content: '';
            position: absolute;
            left: 5px;
            top: 5px;
            // transform: translateY(-50%);
            display: block;
            width: 10px;
            height: 10px;
            align-self: flex-start;
            background-image: url('../../assets/images/check-icon.svg');
            background-size: cover;
            opacity: 0;
            transition: $trans;
        }
    }
    &__input {
        display: none;
        &:checked+label {
            &:before {
                border-color: $color-accent;
            }
            &:after {
                opacity: 1;
            }
        }
    }
}