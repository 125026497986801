.info-card {
    margin-right: 8px;
    padding: 15px;
    border-radius: 8px;
    flex-grow: 1;
    background-color: $default-bg;
    &:last-child {
        margin-right: 0;
    }
    &__header {
        @extend %text--size-h5;
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
        span {
            color: $color-accent;
        }
    }
    &__footer {
        @extend %text--size-h4;
        display: flex;
        flex-direction: column;
    }
}