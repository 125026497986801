.calc-field {
    
    &__field {
        margin-bottom: 24px;
        width: 100%;
        border-bottom: 1px solid $color-la-4;
        display: flex;
        justify-content: space-between;
    }
    &__title {
        @extend %text--size-h5;
        margin-bottom: 8px;
        color: $color-la-2;
    }
    &__input {
        @extend %text--size-p3;
        padding: 0px 0px 12px 0px;
        width: 100%;
        border: none;
        background-color: transparent;
        color: $color-light-text;
        -webkit-appearance: none;
        .input__icon {
            top: 8px;
            right: 3px;
        }
    }
    &__icon {
        @extend %text--size-h4;
        color: $color-la-2;
    }
    &__type {
        display: flex;
        align-items: center;
    }

    label {
        @extend %text--size-h3;
        margin-right: 24px;
        display: flex;
        align-items: center;
        input {
            margin-top: -2px;
            margin-right: 8px;
        }
    }
    
}