@import '../imports';

.object {
    padding-bottom: 48px;
    background-color: $dark-bg;
    &__heading {
        margin-bottom: 32px;
        h2 {
            @extend %text--size-h1;
            margin-bottom: 10px;
        }
        p {
            @extend %text--size-p3;
            color: $color-la-2;
        }
    }
    &__info-cards {
        margin-bottom: 32px;
        display: flex;
    }
    &__info-card {
        .info-card {
            &__footer {
                @extend %text--size-h2;
            }
        }
    }
    &__fields {
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
    }
    &__field {
        @extend %text--size-h4;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-la-4;
        display: flex;
        justify-content: space-between;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
        &-title {
            color: $color-la-2;
        }
        &-value {
            color: $color-light-text;
        }
    }
    &__notes {
        &-header {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
        }
        &-heading {
            @extend %text--size-h4;
        }
        &-change-list {
            @extend %text--size-h5;
            color: $color-accent;
        }
        &-list {
           margin-bottom: 16px;
           a {
               margin-bottom: 8px;
               display: block;
           }
        }
    }
    &__note {
        background-color: $default-bg;
        .report-card {
            &__more {
                circle {

                    fill: $dark-bg;
                }
            }
        }
    }
    &__add-btn {
        position: fixed;
        bottom: 8px;
        left: 16px;
        width: calc(100% - 32px) !important;
    }
}