@import '../imports';

.small-card {
    position: relative;
    padding: 16px;
    background-color: $dark-bg;
    border-radius: 8px;
    user-select: none;
    &__more {
        position: absolute;
        right: 16px;
        top: 16px;
    }
    &__options {
        position: absolute;
        top: 0;
        right: calc(100% + 16px);
        // hidden state styles
        display: none;
        flex-direction: column;
    }
    &--dark-icon {
        .small-card {
            &__more {
                svg {
                    circle {
                        fill: $dark-bg;
                    }
                    path {
                        stroke: #fff;
                    }
                }
            }
        }
    }
}

.small-card.small-card--more-is-active {
    .small-card {
        &__options {
            display: flex;
        }
    }
}