// Перенести в глобальный файл стилей
html {
  font-family: $text-default-font;
  font-weight: $text-default-weight;
  font-size: 10px;
  @include r(380) {
    font-size: 9.5px;
  }
  @include r(350) {
    font-size: 8px;
  }
}

//  Для навигационной плашки (она fixed к верхней границе вьюпорта) 
body {
//   padding-top: 64px;
}
