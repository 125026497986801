.date-picker {
    .react-datepicker {
        width: 100%;
        border: none;
        font-family: $text-default-font;
        &__month-container {
            width: 100%;
            background-color: $default-bg;
        }
        &__month {
            background-color: $default-bg;
            border-color: $default-bg;
        }
        &__navigation {
            top: 3px;
            padding: 0;
            width: 18px;
            height: 14px;
            border: none;
            color: transparent;
            background: url('../../assets/images/arrow-back.svg');
            background-size: cover;
            &--next {
                transform: rotate(180deg);
            }
        }
        &__header {
            margin-top: 0;
            padding-top: 0;
            border-bottom: 1px solid $color-la-4;
            background-color: $default-bg;
        }
        &__triangle {
            display: none;
        }
        &__day-names {
            display: flex;
        }
        &__day-name {
            @extend %text--size-h5;
            padding-bottom: 2px;
            flex-grow: 1;
            font-weight: 700;
            line-height: 2.5em;
            color: $color-la-3;
            text-transform: lowercase;
        }
        &__current-month {
            padding: 0px 0px 16px 0px;
            border-bottom: 1px solid $color-la-4;
            font-size: 1.3rem;
            font-weight: 400;
            color: $color-light-text;
            &::first-letter {
                text-transform: uppercase;
            }
        }
        &__week {
            display: flex;
        }
        &__day {
            @extend %text--size-h3;
            font-weight: 400;
            margin: 2px 10px;
            flex-grow: 1;
            line-height: 2.7rem;
            color: $color-light-text;
            border-radius: 8px;
            &:hover {
                background-color: $color-accent;
                color: $default-bg;
            }
            &--disabled {
                color: $color-la-4;
            }
            &--selected {
                background-color: $color-accent;
                color: $default-bg;
            }
            &--keyboard-selected {
                background-color: $color-accent;
                color: $default-bg;
            }
           
        }
        &__input-container {
            input {
                @extend %text--size-p;
                width: 100%;
                color: $color-primary;
                font-weight: 500;
                &::placeholder {
                    color: inherit;
                }
            }
        }
    }
}