.object-card {
    margin-bottom: 8px;
    padding: 16px;
    border-radius: 10px;
    background-color: $dark-bg;
    color: $color-light-text;
    &__header {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__more {
        cursor: pointer;
    }
    &__title {
        @extend %text--size-h5;
    }
    &__head {
        @extend %text--size-h5;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        color:$color-la-2;
    }
    &__row {
        @extend %text--size-h4;
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid $color-la-4;
        display: flex;
        justify-content: space-between;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }
    &__col {
        width: 22%;
        &:first-child {
            width: 25%;
        }
        &:last-child {
            text-align: right;
            width: 20%;
        }
        &--danger {
            color: $color-danger;
        }
        &--accept {
            color: $color-accent;
        }
    }
}