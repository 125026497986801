@import '../imports';

.sign-up-code {
    position: fixed;
    width: 100vw;
    display: flex;
    flex-direction: column;
    &__main {
        flex-grow: 1;
    }
    &__form {
        padding-top: 32px;
        padding-bottom: 16px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &__field {
        margin-bottom: 16px;
    }
    &__paragraph {
        @extend %text--size-p3;
        max-width: 70%;
        font-weight: 400;
        color: $color-la-2;
    }
    &__buttons {
        position: fixed;
        width: calc(100% - 32px);
        bottom: 16px;
        left: 16px;
    }
    &__button {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}