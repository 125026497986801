.notification-card {
    margin-bottom: 8px;
    padding: 15px 20px;
    border-radius: 10px;
    background-color: $dark-bg;
    &__buttons {
        display: flex;
    }
    &__header {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__date {
        @extend %text--size-h5;
        display: flex;
        align-items: center;
        &:before {
            content: '';
            margin-right: 10px;
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $color-accent;
        }
    }
    &__body {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
    }
    &__message {
        @extend %text--size-p3;
        line-height: 20px;
        max-width: 75%;
        span {
            color: $color-accent;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        text-align: right;
        p {
            @extend %text--size-h5;
            margin-bottom: 3px;
            color: $color-la-2;
        }
        span {
            @extend %text--size-h4;
            color: $color-accent;
        }
    }
    &__btn {
        margin-right: 16px;
        flex-grow: 1;
        width: auto;
        &:last-child {
            margin-right: 0;
        }
    }
}