/*
 * Color Palette
 */
$default-bg:                        #1E1F26;
$dark-bg:                           #16171C;
$color-accent:                      #78CBBB;
$color-danger:                      #FF2D5E;
$color-dark:                        #0F0F13;



$color-accent-light:                #D6F1FF;
$color-primary:                     rgba(45,67,121,1);
$color-secondary:                   #1D1E20;
$color-secondary-light:             #6F7175;
$color-secondary-super-light:       #A5A7A9;
$color-light-border:                #2d437961;
$color-light-text:                  #fff;
$color-blue:                        #569BBE;
$color-light:                       #fff;
$color-accent-bg:                   #0083FF;
$color-dark-background-accent:      #585A61;


// alpha
$color-primary-alpha: rgba(45,67,121, .64);
$color-primary-alpha-4: rgba(45,67,121, .48);
$color-primary-alpha-5: rgba(45,67,121, .16);

$z-over-default: 3;
$z-nav-panel: 10;
$z-new-note-expanded-header: 11;

//  light color with alpha channel
$color-la-1: rgba(255,255,255, 0.48);
$color-la-2: rgba(255,255,255, 0.32);
$color-la-3: rgba(255,255,255, 0.20);
$color-la-4: rgba(255,255,255, 0.08); // for borders, lines and outlines  
