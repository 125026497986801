.radio-button {
    cursor: pointer;
    &__label {
        &:before {
            content: '';
            margin-right: 16px;
            height: 20px;
            width: 20px;
            display: block;
            box-sizing: border-box;
            background-color: transparent;
            border: 1px solid rgba(120, 203, 187, 0.32);
            border-radius: 50%;
            transition: $trans;
        }
    }
    &__input {
        display: none;
        &:checked+label {
            &:before {
                border-color: $color-accent;
                border-width: 4px;
            }
        }
    }
    
}