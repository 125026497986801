.nav-panel {
    padding: 16px 16px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $dark-bg;
    &__title {
        @extend %text--size-h3;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        font-weight: 400;
        svg {
            transform: translateY(4px);
        }
        span {
            @extend %text--size-h5;
            margin-top: 2px;
            letter-spacing: normal;
            font-weight: 400;
            text-align: center;
            color: $color-la-3;
        }
    }
    &__buttons {
        display: flex;
    }
    &__button {
        margin-left: 16px;
        &:first-child {
            margin-left: 0;
        }
    }
}