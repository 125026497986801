@import '../imports';

.popup {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0px 16px;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $dark-bg;
    z-index: $z-over-default;

    // state styles
    visibility: hidden;
    opacity: 0;
    &__close {
        position: absolute;
        top: 17px;
        right: 19px;
    }
    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }
    &__footer {
        margin-bottom: 16px;
    }
    &__button {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.popup.popup--is-open {
    visibility: visible;
    opacity: 1;
}

.popup.popup--transparent {
    background-color: rgba(30,31,38,0.88);
    .popup {
        &__footer {
            background-color: #1e1f26;
        }
        &__close {
            display: none;
        }
    }
}

.popup.popup--has-top-offset {
    top: auto;
    bottom: 0;
    height: calc(100vh - 60px);
}