

@font-face {
    font-family: 'SF-Pro-Display';
    src: url(../../fonts/SF/SF-Pro-Display-Regular.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF-Pro-Display';
    src: url(../../fonts/SF/SF-Pro-Display-Medium.otf) format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF-Pro-Display';
    src: url(../../fonts/SF/SFProDisplay-Bold.woff2) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


// @font-face {
//     font-family: 'Manrope-Medium';
//     src: url('../../fonts/Manrope/Manrope-Medium.ttf') format('ttf');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Manrope-SemiBold';
//     src: url('../../fonts/Manrope/Manrope-SemiBold.ttf') format('truetype');
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
// }
