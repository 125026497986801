@import '../imports';
@import '../components//notification-card';

.notifications {
    background-color: $dark-bg;
    &__heading {
        margin-bottom: 32px;
        h2 {
            @extend %text--size-h1;
            margin-bottom: 10px;
        }
        p {
            @extend %text--size-p3;
            color: $color-la-2;
        }
    }
    &__objects {
        background-color: $default-bg;
    }
}