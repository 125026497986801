.report-card {
    padding: 16px 10px;
    display: flex;
    align-items: center;
    background-color: $dark-bg;
    border-radius: 10px;
    color: $color-light-text;
    &__number {
        @extend %text--size-h5;
        position: relative;
        padding-right: 15px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        color: $color-la-2;
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            display: block;
            height: 28px;
            width: 1px;
            background-color: $color-la-4;
        }
    }
    &__title {
        @extend %text--size-h4;
        flex-grow: 1;
    }
    &__more {
        margin-left: auto;
    }
}