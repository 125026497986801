@import '../imports';

.contragent-create-note {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    &__body {
        flex-grow: 2;
        margin-top: 32px;
    }
    &__agent-info {
        margin-bottom: 32px;
    }
    &__name {
        @extend %text--size-h1;
        margin-bottom: 8px;
    }
    &__role {
        @extend %text--size-h4;
        color: $color-la-2;
    }
    
    &__date-box {
        margin-bottom: 32px;
        display: flex;
        justify-content: space-between;
    }
    &__date {
        width: calc(50% - 16px);
    }
    &__percent {
        h3 {
            @extend %text--size-h5;
            margin-bottom: 8px;
            font-weight: 400;
        }
    }
    &__footer {
        padding-bottom: 16px;
    }
}