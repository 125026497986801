@import '../imports';

.input {
    overflow: hidden;
    &__title {
        @extend %text--size-h5;
        transform: translateY(18px) translateX(10.3%) scale(1.2);
        margin-bottom: 4px;
        height: 15px;
        font-weight: 400;
        color: $color-la-2;
        transition: .2s ease;
    }
    &__user-area {
        position: relative;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $color-la-3;
    }
    &__input-tag {
        @extend %text--size-p2;
        position: relative;
        font-family: $text-default-font;
        padding-bottom: 8px;
        width: 100%;
        background-color: transparent;
        color: $color-light-text;
        &::placeholder {
            font-family: $text-default-font;
            color: $color-light-text;
        }
        &:before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 0;
            width: 100%;
            height: 30px;
            display: block;
        }
    }
    &__icon {
        position: absolute;
        right: 18px;
        top: 3px;
        transform: translateY(-50%);
    }
    
}

.input.input--is-active {
    .input {
        &__title {
            transform: translateY(0)
        }    
        &__user-area {
            border-color: $color-la-1;
        }
        &__icon {
            svg {
                path {
                    opacity: 1;
                }
            }
        }
    }
}