@import '../imports';
@import '../components/contragent-card';

.contragents {
    padding-bottom: 64px;
    &__header {
        margin-bottom: 16px;
        margin-left: -16px;
        padding-bottom: 16px;
        padding-left: 16px;
        width: 100vw;
        background-color: $dark-bg;
        p {
            @extend %text--size-h5;
            margin-bottom: 8px;
            color: $color-la-2;
        }
    }
    &__agent-card {
        margin-bottom: 8px;
    }
    &__btn-add {
        position: fixed;
        max-width: calc(100% - 32px);
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
    }
}