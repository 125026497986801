.custom-field {
    margin-bottom: 24px;
    width: 100%;
    border-bottom: 1px solid $color-la-4;
    display: flex;
    justify-content: space-between;
    &__input {
        @extend %text--size-p3;
        padding: 0px 0px 12px 0px;
        width: 100%;
        border: none;
        background-color: transparent;
        color: $color-light-text;
        -webkit-appearance: none;
    }
}