@import '../imports';
@import '../components/inputRange';

.contragent {
    padding-bottom: 64px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    &__header {
        background-color: $dark-bg;
    }
    &__agent-info {
        margin-bottom: 32px;
    }
    &__name {
        @extend %text--size-h1;
        margin-bottom: 8px;
    }
    &__role {
        @extend %text--size-h4;
        color: $color-la-2;
    }
    &__actual-percent {
        padding-bottom: 16px;
        h3 {
            @extend %text--size-h5;
            margin-bottom: 8px;
            font-weight: 400;
        }
    }
    &__body {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 2;
    }
    &__add-note {
        margin-bottom: 16px;
        padding-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
            @extend %text--size-h3;
            font-weight: 400;
        }
        &-icon {
            cursor: pointer;
        }
    }
    &__notes {
        position: relative;
        flex-grow: 2
    }
    &__note {
        margin-bottom: 8px;
    }
    &__no-notes {
        @extend %text--size-h4;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: $color-la-2;
    }
    &__footer {
        position: fixed;
        bottom: 16px;
        width: 100vw;
    }
    .input-range {
        margin: 0 auto;
        max-width: 95%;
        height: auto;
    }
}