@import '../imports';
@import '../components/search-list';
@import '../components/date-picker';
@import '../components/custom-field';
@import '../components/radio-button';
@import '../components/calc-field';
@import '../components/checkbox';

.new-note {
    position: fixed;
    top: 0;
    left: 0;
    padding-bottom: 16px;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    &__nav {
        display: flex;
        &-btn {
            @extend %text--size-h4;
            margin-right: 16px;
            width: 50%;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__content {
        padding: 24px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        @media screen and (orientation:landscape) {
            padding-top: 0;
        }
    }
    &__header {
        @media screen and (orientation:landscape) {
            display: none;
        }
        &--expanded {
            position: fixed;
            left: 0;
            top: 0;
            padding: 32px 16px 0px 16px;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            background-color: $default-bg;
            z-index: $z-new-note-expanded-header;

            .new-note {
                &__more-fields {
                    position: fixed;
                    bottom: 16px;
                    width: 90%;
                    svg {
                        transform: rotate(180deg);
                    }
                }
                &__fields-slider {
                    width: 100%;
                    height: auto;
                }
            }
            .swiper-wrapper {
                transform: none !important;
            }
        }
    }
    &__footer {
        position: fixed;
        width: calc(100% - 32px);
        bottom: 16px;
    }
    &__more-fields {
        @extend %text--size-h4;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }
    &__fields-slider {
        margin-bottom: 4px;
        height: 125px;
        @include rhmin(700) {
            height: 175px;
        }
        @include rhmin(770) {
            height: 220px;
        }
    }
    &__fields-box {
        display: flex;
        justify-content: space-between;
        &-field {
            width: calc(50% - 12px);
        }
        &+.new-note__title {
            margin-top: 16px;
            margin-bottom: 16px;
        }
       
    }
    &__buttons {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        .btn {
            width: calc(50% - 8px);
        }
    }
    &__body {
        width: 100%;
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        @include rhmin(750) {
            height: 35vh;
        }
        .swiper {
            &-pagination {
                margin-bottom: 20px;
                margin-left: -16px;
                width: calc(100% + 32px);
                display: flex;
                justify-content: space-between;
                overflow-x: scroll;
                background-color: $color-la-4;
                .swiper-pagination-bullet {
                    @extend %text--size-h5;
                    padding: 16px 16px;
                    text-transform: uppercase;
                    color: $color-la-2;
                    &-active {
                        color: $color-light;
                    }
                }
            }
        }
    }
    &__title {
        @extend %text--size-h5;
        margin-bottom: 1em;
        color: $color-la-2;
    }
    &__progress {
        &-bar {
            position: relative;
            height: 1px;
            background-color: $color-la-2;
            border-radius: 8px;
            overflow: hidden;
            span {
                position: absolute;
                left: 0;
                top: 0;
                width: 15%;
                height: 100%;
                background-color: $color-accent;
                transition: $trans;
                z-index: -1;
            }
        }
        &-text {
            @extend %text--size-h4;
            text-align: center;
            color: $color-la-1;
        }
    }
    &__datepicker {
        .react-datepicker {
            &__header  {
                border-top: none;
            }
            &__day {
                position: relative;
                margin-right: 12px;
                margin-left: 12px;
                &:last-child {
                    margin-right: 0;
                }
                &:first-child {
                    margin-left: 0;
                }
                &--in-range {
                    background: transparent;
                    &:before {
                        content: '';
                        position: absolute;
                        height: 100%;
                        display: block;
                        width: calc(100% + 24px);
                        background-color: $color-accent;
                        opacity: 0.16;
                        z-index: $z-over-default;
                    }
                }
                &--in-selecting-range {
                    background: transparent;
                    &:before {
                        content: '';
                        position: absolute;
                        width: calc(100% + 24px);
                        height: 100%;
                        display: block;
                        background-color: $color-accent;
                        opacity: 0.16;
                        z-index: $z-over-default;
                    }
                }
                &--selected {
                    background-color: $color-accent;
                    &:before {
                        left: 0;
                    }
                }
                &--keyboard-selected {
                    background-color: $color-accent;
                    &:before {
                        right: 0;
                        width: 100%;
                    }
                }
            }
            &__week {
                margin-bottom: 12px;
                border-radius: 8px;
                overflow: hidden;
                
            }
        }
    }
}