@import '../imports';

.editable-field {
    margin-bottom: 14px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-la-4;
    cursor: pointer;
    &--empty {
        .editable-field {
            &__icon {
                svg {
                    path {
                        fill: $color-la-2;
                    }
                }
            }
            &__name {
                color: $color-la-2;
            }
        }
    }
    &--active {
        .editable-field {
            &__icon {
                svg {
                    path {
                        fill: $color-light;
                    }
                }
            }
            &__name {
                color: $color-light-text;
            }
        }
    }
    &__icon {
        margin-bottom: -1px;
        margin-right: 8px;
    }
    &__name {
        @extend %text--size-h4;
        margin-right: auto;
        color: $color-accent;
    }
    &__value {
        @extend %text--size-h4;
        margin-right: 16px;
    }
    &__arrow {
        margin-bottom: -3px;
    }
}