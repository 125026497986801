@import '../imports';

.note-info {
    display: flex;
    flex-direction: column;
    &__header {
        @extend %text--size-h5;
        margin-bottom: 4px;
        display: flex;
    }
    &__sum {
        margin-right: 8px;
        color: $color-danger;
    }
    &__name {
        color: #fff;
    }
    &__footer {
        @extend %text--size-h5;
        font-weight: 400;
        display: flex;
        color: $color-la-2;
    }
    &__number {
        display: flex;
        align-items: center;
        &:after {
            content: '';
            margin: 0px 6px 0px 8px;
            display: block;
            width: 1px;
            height: 10px;
            background-color: $color-la-2;
        }
    }
    &__date {
        display: flex;
        align-items: center;
        &:after {
            content: '';
            margin: 0px 6px 0px 8px;
            display: block;
            width: 1px;
            height: 10px;
            background-color: $color-la-2;
        }
    }
}