@import '../imports';

.user-avatar {
    &__picture {
        position: relative;
        height: 32px;
        width: 32px;
        display: flex;
        border-radius: 50%;
        img {
            width: 100%;
            height: 100%;
            border-radius: inherit;
        }
    }
    &__status {
        position: absolute;
        bottom: -2px;
        right: 0;
        height: 12px;
        width: 12px;
        border: 2px solid $color-light;
        border-radius: 50%;
        background-color: $color-accent;
    }
}