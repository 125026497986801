@import '../imports';

.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__icon {
        margin-bottom: 25px;
    }   
    &__title {
        @extend %text--size-h1;
        font-weight: 400;
        margin-bottom: 32px;
    }
    &__content {
        @extend %text--size-p2;
        max-width: 77%;
        font-weight: 400;
        color: $color-la-2;
        text-align: center;
    }
}