.search-list {
    position: relative;
    &--is-open {
        .search-list__options {
            opacity: 1;
            visibility: visible;
        }
    }
    &--is-active {
        .search-list {
            &__field {
                border-color: $color-la-1;
            }
            &__input {
                &::placeholder {
                    color: $color-la-1;
                }
            }
        }
    }
    &__options {
        background-color: $default-bg;
        z-index: $z-over-default;
    }
    &__field {
        margin-bottom: 16px;
        width: 100%;
        border-bottom: 1px solid $color-la-3;
        display: flex;
        justify-content: space-between;
        svg {
            margin-right: 3px;
        }
    }
    &__btn {
        position: relative;
        left: -5px;
        font-size: 1.4rem;
        cursor: pointer;
    }

    &__input {
        @extend %text--size-p2;
        padding: 0px 0px 12px 0px;
        width: 100%;
        border: none;
        background-color: transparent;
        color: $color-light-text;
        &::placeholder {
            font-family: $text-default-font;
        }
    }
    &__add-option {
        @extend %text--size-p4;
        margin-bottom: 16px;
        padding: 12px 0px;
        border-bottom: 1px solid $color-la-4;
        color: $color-accent;
    }
    &__results {
        position: relative;
        max-height: 20vh;
        overflow-y: scroll;
        &:after {
            content: '';
            position: sticky;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 30px;
            background: linear-gradient(180deg, rgba(30, 31, 38, 0) 0%, #1E1F26 57.81%);
        }
        @include rhmin(700) {   
            max-height: 26vh;
        }
        @include rhmin(800) {
            max-height: 30vh;
        }
        @media screen and (orientation:landscape) {
            max-height: 33vh ;
        }
        &::-webkit-scrollbar {
            width: 2px;
            height: 2px;
        }
        
        &::-webkit-scrollbar-track {
            position: absolute;
            border: 3px solid $color-la-3;
            border-radius: 1px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        }
        
        &::-webkit-scrollbar-thumb {
            background: #fff !important;  
            border-radius: 1px;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: #fff;  
        }
    }
    &__option {
        @extend %text--size-p2;
        max-width: 90%;
        margin-bottom: 12px;
        font-weight: 400;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__no-results {
        @extend %text--size-p2;
        padding-top: 16px;
        font-weight: 400;
        color: $color-la-3;
        text-align: center;
        span {
            color: #fff;
        }
    }
}