

@mixin btn {
    padding: 12px 24px;
    width: 100%;
    border: 1px solid $color-accent;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.4rem;
    background-color: transparent;
    text-decoration: none;
    color: #fff;
    transition: $trans;
    user-select: none;
    &:active {
        transform: scale(0.96)
    }
}

.btn {
    @include btn;
}

.btn.btn--sm {
    @extend %text--size-h5;
    padding: 4px 10px;
}

.btn.btn--danger {
    border-color: $color-danger;
    color: $color-danger;
}

.btn.btn--accept {
    border-color: $color-accent;
    color: $color-accent;
}
.btn.btn--accent {
    background-color: $color-accent;
    color: #1E1F26;
}
.btn.btn--disabled {
    background-color: transparent;
    border-color: $color-la-2;
    color: $color-la-1;
}