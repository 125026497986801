@import '../imports';

.single-note {
    min-height: 100vh;
    background-color: $dark-bg;
    &__header {
        margin-bottom: 32px;
        h2 {
            @extend %text--size-h1;
            position: relative;
            margin-bottom: 10px;
            display: inline-block;
            &:after {
                content: '';
                position: absolute;
                right: -15px;
                top: -2px;
                display: block;
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: $color-accent;
            }
        }
        p {
            @extend %text--size-p3;
            color: $color-la-2;
        }
    }
    &__edit {
        position: absolute;
        bottom: 16px;
        left: 16px;
        //Фиксит баг переопределения Убрать стили кнопки из extends, т.к они каждый раз импортируются в каждый файл, что вызывает переопределение.
        width: calc(100% - 32px) !important;
    }
}