@import '../imports';

.percent-record {
    display: flex;
    flex-direction: column;
    &__percent {
        @extend %text--size-h1-lg;
        margin-bottom: 8px;
        color: $color-accent;
    }
    &__dates {
        display: flex;
    }
    &__date {
        @extend %text--size-h4;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        color: #fff;
        span {
            @extend %text--size-h5;
            margin-bottom: 4px;
            color: $color-la-2;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}