.user-reports {
    padding-top: 24px;
    &__header {
        padding-bottom: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__heading {
        @extend %text--size-h3;
    }
    &__add-object {
        cursor: pointer;
    }
    &__tabs {
        @extend %text--size-h5;
        margin-bottom: 16px;
        padding: 5px;
        border-radius: 10px;
        display: flex;
        background-color: #0F0F13;
    }
    &__tab {
        padding: 8px;
        width: 50%;
        flex-grow: 1;
        text-align: center;
        border-radius: 10px;
        color: $color-la-2;
        cursor: pointer;
        &--is-active {
            background-color: $color-accent;
            color: $color-dark;
        }
    }
    &__list {
        margin-bottom: 16px;
        a {
            margin-bottom: 8px;
            display: block;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}