.input-range {
    &__header {
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
    }
    &__value {
        @extend %text--size-h1-lg;
        color: $color-accent;
    }
    &__unit {
        @extend %text--size-h1-lg;
        color: $color-la-2;
    }
    &__input-container {
        position: relative;
        span {
            position: absolute;
            top: 9px;
            left: 0;
            height: 1px;
            background-color: $color-accent;
        }
    }
    &__input {
        margin-bottom: 16px;
        -webkit-appearance: none;  /* Override default CSS styles */
        appearance: none;
        width: 100%; /* Full-width */
        height: 1px;
        background: $color-la-2; /* Grey background */
        outline: none; /* Remove outline */
        -webkit-transition: .2s; /* 0.2 seconds transition on hover */
        transition: opacity .2s;
        &::-webkit-slider-thumb {
            -webkit-appearance: none; /* Override default look */
            appearance: none;
            width: 20px; /* Set a specific slider handle width */
            height: 20px; /* Slider handle height */
            background: $color-accent; /* Green background */
            border-radius: 50%;
            cursor: ew-resize;
        }
        &::-moz-range-thumb {
            width: 20px; /* Set a specific slider handle width */
            height: 20px; /* Slider handle height */
            background: #4CAF50; /* Green background */
            cursor: pointer; /* Cursor on hover */
        } 
        &::-moz-range-progress {
            background-color: #43e5f7; 
        }
        &::-ms-fill-lower {
            background-color: #43e5f7; 
        }
        
    }
    &__footer {
        display: flex;
        justify-content: space-between;
    }
    &__min {
        @extend %text--size-h5;
        color: $color-la-2;
    }
    &__max {
        @extend %text--size-h5;
        color: $color-la-2;
    }
}