/*
 * Text
 *
 * Settings for text, fonts, sizes
 */

$text-default-font:         "SF-Pro-Display";
$text-second-font:          "SF-Pro-Display";

$text-h1-lg-font:           $text-default-font;
$text-default-weight:       500;



/**
 * Text sizes
 *
 * Resolution specific font sizes, line height, etc.
 * All styles cascade up
 *
 * For each style is generated "%text--size-NAME" placeholder selector
 * Each style can also be used included using mixin "@include text--size(NAME);"
 *
 * See extends/_text-styles.scss
 *
 * @example
 *     .my-text-a {
 *         @extend %text--size-lead;
 *     }
 *     // or
 *     .my-text-b {
 *         @include text--size(lead);
 *     }
 */

$text-sizes: (
    xs: (
        'default': ( 'font-size': 1.2rem,  'line-height': 12 / 12 * 1em, 'letter-spacing': 0.01em ),
        'small':   ( 'font-size': 1.4rem,  'line-height': 16 / 14 * 1em, 'letter-spacing': 0.01em ),
        'smaller': ( 'font-size': 1.0rem,  'line-height': 12 / 10 * 1em, 'letter-spacing': 0.12em ),
        'nav-links': ( 'font-size': 1.4rem,  'line-height': 16 / 14 * 1em),

        // Headings
        'h1-lg':      ( 'font-size': 3.2rem,  'line-height': 32 / 32 * 1em, 'letter-spacing': -0.04em ),
        'h1':      ( 'font-size': 2.4rem,  'line-height': 24 / 24 * 1em, 'letter-spacing': -0.02em ),
        'h2':      ( 'font-size': 2.0rem,  'line-height': 24 / 20 * 1em, 'letter-spacing': -0.02em ),
        'h3':      ( 'font-size': 1.6rem,  'line-height': 20 / 16 * 1em, 'letter-spacing': -0.02em ),
        'h4':      ( 'font-size': 1.4rem,  'line-height': 16 / 14 * 1em, 'letter-spacing': -0.02em ),
        'h5':      ( 'font-size': 1.2rem,  'line-height': 16 / 12 * 1em, 'letter-spacing': -0.02em ),

        'p':  ( 'font-size': 2.0rem,  'line-height': 24 / 20 * 1em, 'letter-spacing': -0.02em ),
        'p2': ( 'font-size': 1.6rem,  'line-height': 20 / 16 * 1em, 'letter-spacing': -0.02em ),
        'p3': ( 'font-size': 1.4rem,  'line-height': 16 / 14 * 1em, 'letter-spacing': -0.02em ),
        'p4': ( 'font-size': 1.2rem,  'line-height': 16 / 12 * 1em, 'letter-spacing': -0.02em ),


    ),
   
    md: (
        'default': ( 'font-size': 1.4rem,  'line-height': 16 / 14 * 1em ),
        'small': ( 'font-size': 1.8rem,  'line-height': 20 / 18 * 1em ),
        'smaller': ( 'font-size': 1.1rem,  'line-height': 12 / 11 * 1em ),
        
        // Paragraphs
        'p': ( 'font-size': 1.6rem,  'line-height': 24 / 16 * 1em),

        // Headings
        'h1-lg':   ( 'font-size': 13.6rem,  'line-height': 136 / 136 * 1em),
        'h1':      ( 'font-size': 5.6rem,  'line-height': 64 / 46 * 1em ),
        'h4':      ( 'font-size': 1.8rem,  'line-height': 20 / 18 * 1em),
        'h5':      ( 'font-size': 1.6rem,  'line-height': 18 / 16 * 1em ),
        
        // footer
        'footer-h':      ( 'font-size': 1.6rem,  'line-height': 20 / 16 * 1em ),
    ),

    lg: (

         // Paragraphs
        'p-sm': ( 'font-size': 1.8rem,  'line-height': 28 / 18 * 1em),

        // Headings
        'h1-lg':   ( 'font-size': 17.6rem,  'line-height': 176 / 176 * 1em),
        'h4':      ( 'font-size': 2.0rem,  'line-height': 20 / 20 * 1em),
    ),
    xxl: (
        'default': ( 'font-size': 1.8rem,  'line-height': 20 / 18 * 1em ),
        'smaller': ( 'font-size': 1.4rem,  'line-height': 16 / 14 * 1em ),

        // Paragraphs
        'p-sm': ( 'font-size': 2.0rem,  'line-height': 32 / 20 * 1em),

        // Headings
       
       
        'h1-lg':   ( 'font-size': 19.2rem,  'line-height': 192 / 192 * 1em),
    ),

    xxxl: (

        // Headings
        'h1':      ( 'font-size': 5.6rem,  'line-height': 72 / 56 * 1em ),
    ),

    xxxxl: (

        // Headings
        'h1':      ( 'font-size': 10.0rem,  'line-height': 90 / 100 * 1em ),
    )

);
