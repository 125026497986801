.contragent-card {
    padding: 16px;
    background-color: $dark-bg;
    border-radius: 8px;
    &__header {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__name {
        @extend %text--size-h4;
        color: #fff;
    }
    &__more {
        cursor: pointer;
    }
    &__info {
        display: flex;
        align-items: center;
        span {
            @extend %text--size-h4;
            font-weight: 400;
            color: $color-la-2;
        }
    }
    &__proccent {
        @extend %text--size-h1-lg;
        font-weight: 400;
        margin-right: 16px;
        color: $color-accent;
    }
}